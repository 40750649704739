/* Modal.css */
.ReactModal__Overlay {
  z-index: 1000; /* 모달이 가려지는 경우 z-index를 조정합니다 */
  background-color: rgba(0, 0, 0, 0.5); /* 배경색 설정 */



}

/* Header */
.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #333;
  color: white;
  padding: 20px;
  border-bottom: 1px solid #555;
}

.logged-in-user-info {
  text-align: center;
}

.logged-in-user-info p {
  margin: 10px 0;
  font-size: 18px;
}

.auth-buttons button {
  background-color: #fff;
  color: #333;
  border: 1px solid #555;
  padding: 10px 20px;
  cursor: pointer;
  margin: 5px;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.3s, color 0.3s;
}

.auth-buttons button:hover {
  background-color: #333;
  color: #fff;
  border: 1px solid #fff;
}

/* Modal Style */
.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.75) !important;
}

.ReactModal__Content {
  background-color: #fff;
  color: #333;
  padding: 20px;
  border-radius: 8px;
  margin: auto;
  border: 1px solid #ddd;
  width: 65%;
}

h2 {
  text-align: center;
  color: #333;
}

form input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

form button {
  width: 100%;
  padding: 10px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

form button:hover {
  background-color: #555;
}

button {
  background-color: #333;
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #555;
}

/* Close and Toggle Buttons */
button:disabled {
  background-color: #888;
  cursor: not-allowed;
}

/* Modal Toggle Buttons */
button {
  margin-top: 10px;
  background-color: #555;
  border: none;
  padding: 10px;
  cursor: pointer;
  color: white;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #333;
}

/* ... existing code ... */

.signup-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 2.5rem;
  border-radius: 15px;
  width: 400px; /* 모달 전체 너비 조정 */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  max-width: 90vw; /* 화면 너비의 90%를 넘지 않도록 설정 */
}

.signup-form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  width: 100%; /* 폼의 너비를 모달에 맞춤 */
  align-items: center; /* 중앙 정렬 추가 */

}

.signup-form input {
  width: calc(100% - 30px); /* 패딩값을 고려한 너비 설정 */
  padding: 12px 15px;
  border: 2px solid #e1e1e1;
  border-radius: 8px;
  font-size: 15px;
  transition: all 0.3s ease;
  background-color: #f8f9fa;
  box-sizing: border-box; /* 패딩과 보더를 너비에 포함 */
}

.signup-form input:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.1);
  outline: none;
}

.signup-form input:disabled {
  background-color: #e9ecef;
  cursor: not-allowed;
  border-color: #ced4da;
}

.signup-form button {
  width: 100%; /* 버튼 너비를 폼에 맞춤 */
  padding: 12px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.3s ease;
  margin-top: 0.5rem;
  box-sizing: border-box; /* 패딩을 너비에 포함 */
}

.signup-form button:hover:not(:disabled) {
  background-color: #0056b3;
  transform: translateY(-1px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.signup-form button:disabled {
  background-color: #b0b0b0;
  cursor: not-allowed;
}

.timer-text {
  color: #dc3545;
  font-size: 14px;
  text-align: center;
  margin: 8px 0;
  font-weight: 500;
}

/* 모달 제목 스타일 */
.signup-modal h2 {
  margin-bottom: 2rem;
  color: #2c3e50;
  text-align: center;
  font-size: 1.8rem;
  font-weight: 700;
}

/* 인증 관련 버튼 스타일 */
.signup-form button[type="button"]:not(:last-child) {
  background-color: #6c757d;
}

.signup-form button[type="button"]:not(:last-child):hover:not(:disabled) {
  background-color: #5a6268;
}

/* 이전 버튼 스타일 */
.signup-form button[type="button"]:last-child {
  background-color: #6c757d;
  margin-top: 1rem;
}

.signup-form button[type="button"]:last-child:hover {
  background-color: #5a6268;
}

/* 입력 필드 그룹 스타일 */
.input-group {
  position: relative;
  margin-bottom: 1rem;
}

.input-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #495057;
  font-size: 0.9rem;
  font-weight: 500;
}

/* 에러 메시지 스타일 */
.error-message {
  color: #dc3545;
  font-size: 0.85rem;
  margin-top: 0.25rem;
}

/* 성공 메시지 스타일 */
.success-message {
  color: #28a745;
  font-size: 0.85rem;
  margin-top: 0.25rem;
}

/* 인증 코드 입력 섹션 */
.verification-section {
  width: 100%;
  background-color: #f8f9fa;
  padding: 1rem;
  border-radius: 8px;
  margin: 1rem 0;
  box-sizing: border-box;
}

/* 반응형 디자인 */
@media (max-width: 576px) {
  .signup-modal {
    width: 90%;
    padding: 2rem;
  }
  
  .signup-form input,
  .signup-form button {
    padding: 12px;
  }
  
  .signup-modal h2 {
    font-size: 1.5rem;
  }
}