/* Sidebar 스타일 */
.sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 250px;
  background-color: #F9FAFB;
  color: #333;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  /*
  padding: 10px;
  */
  transition: all 0.3s ease;
}

/* TopBar 스타일 */
.top-bar {
  background-color: #fff;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /*
padding: 0 20px;
  */
}

.top-bar-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

/* 로그아웃 버튼 스타일 */
.logout-button {
  background-color: #FF6B6B;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.logout-button:hover {
  background-color: #ff4c4c;
}

/* 검색 바 스타일 */
.search-bar {
  padding: 10px 0;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
}

.search-bar input {
  width: 80%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  color: #333;
  transition: border-color 0.3s ease;
}

.search-bar input:focus {
  border-color: #ff6b6b;
  outline: none;
}

/* 검색 버튼 스타일 */
.search-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.search-button svg {
  stroke: #000; /* SVG 아이콘을 검정색으로 설정 */
}

.search-button:hover svg {
  stroke: #555; /* Hover 시 약간 밝은 검정색으로 */
}

/* 환자 리스트 스타일 */
.patient-list {
  padding: 0;
  margin: 0;
  flex-grow: 1;
  overflow-y: auto;
}

.patient-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
  height: 100%; /* ul이 div 전체를 채우도록 설정 */
  display: flex;
  flex-direction: column;
}

.patient-list li {
  background-color: #fff;
  color: #333;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  transition: transform 0.3s, box-shadow 0.3s;
  flex-shrink: 0; /* li 항목들이 일정 크기를 유지하도록 설정 */
}

.patient-list li:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.patient-list li p {
  margin: 0;
  font-size: 16px;
  color: #333;
}

.patient-list li p strong {
  font-size: 16px;
}

.patient-info-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.compact {
  padding: 2px 0; /* 상하 여백 더 줄이기 */
  margin: 0; /* 여백 제거 */
}

.patient-info-row p {
  margin: 0;
}

.patient-info-row button {
  background: none;
  border: none;
  cursor: pointer;
}

.compact-button {
  padding: 0;
  margin: 0;
  font-size: 12px; /* 텍스트 크기 유지 */
}

.patient-info-row svg {
  width: 16px; /* SVG 아이콘 크기 더 줄이기 */
  height: 16px;
}

.patient-list li {
  padding: 3px; /* 패딩을 최소화하여 높이 절감 */
  margin-bottom: 3px; /* 항목 간의 여백 최소화 */
  border-radius: 4px;
}
