.patient-info-card,
.patient-info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
}

.patient-details {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  flex: 1;
}

.patient-details div {
  display: flex;
  align-items: center;
  font-size: 17px;
  color: #333;
}

.patient-details strong {
  margin-right: 5px;
  font-weight: 600;
  color: #555;
}

.period-buttons {
  display: flex;
  gap: 8px;
  margin-left: 20px;
}

.period-buttons button {
  background-color: #f0f0f0;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.period-buttons button:hover {
  background-color: #007bff;
  color: white;
}

.selected-period {
  background-color: #007bff;
  color: white;
  border: none;
}
