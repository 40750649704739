:root {
  --scale-factor: 1;
}

.dashboard {
  display: grid;
  grid-template-columns: 250px 1fr 1fr;
  grid-template-rows: minmax(50px, 10%) minmax(200px, 45%) minmax(200px, 45%);
  grid-gap: 10px;
  height: 100vh;
  box-sizing: border-box;
  padding: 10px;
  transform-origin: top left;
  transform: scale(var(--scale-factor));
  width: calc(100% / var(--scale-factor));
  height: calc(100vh / var(--scale-factor));
}

/* 노트북 화면 (1366px 이하) */
@media screen and (max-width: 1366px) {
  :root {
    --scale-factor: 0.75;
  }
  .dashboard-container {
    width: calc(100% / var(--scale-factor));
  }
}

/* 작은 노트북 화면 (1024px 이하) */
@media screen and (max-width: 1180px) {
  :root {
    --scale-factor: 0.7;
  }
  .dashboard-container {
    width: calc(100% / var(--scale-factor));
  }
}

/* 태블릿 화면 (768px 이하) */
@media screen and (max-width: 768px) {
  :root {
    --scale-factor: 0.65;
  }
  .dashboard-container {
    width: calc(100% / var(--scale-factor));
  }
}
/* 차트의 크기를 키우지 않도록 설정 */
.chart-wrapper {
  width: 100%;
  height: 100%; /* 부모 요소에 맞춤 */
  max-width: 100%; /* 컨테이너 너비 제한 */
  max-height: 100%; /* 컨테이너 높이 제한 */
  overflow-x: auto; /* 가로 스크롤 활성화 */

}
/* 사이드바 */
.sidebar {
  grid-row: 1 / span 3;
  grid-column: 1 / 2;
  background-color: #f9fafb;

  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}
.hospital-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain; /* 이미지가 부모 컨테이너에 맞게 조정되도록 설정 */
}
/* patient-info */
.patientinfo {
  grid-row: 1 / 2;
  grid-column: 2 / 4;
  background-color: #fff;
  padding: 10px;
  /*
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  */
  height: auto;
  display: flex;
  align-items: center;
}
/* patientgrid */
.patientgrid {
  grid-row: 2 / 3;
  grid-column: 2 / 4;
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 95%; /* 높이를 약간 줄여 하단과 여백 확보 */
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.patientgrid-content {
  flex: 1;
  overflow-y: auto;
}
/* devicestatusform */
.devicestatus {
  grid-row: 3 / 4;
  grid-column: 2 / 4;
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
/* diet */
.diet {
  grid-row: 3 / 4;
  grid-column: 3 / 4;
  background-color: #fff;
  padding: 0px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 100%; /* 필요에 따라 높이를 조정하여 화면에 맞춤 */
  overflow-y: auto; /* 스크롤 활성화 */
}

/* Flexbox로 외부 컴포넌트 배치 */
.dashboard-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
}

.grid-container {
  flex: 0 0 75%;
}

.stats-container {
  flex: 0 0 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
/*   overflow-x: auto; 

  */
  transform-origin: top left;
  transform: scale(var(--scale-factor));
  height: calc(100vh / var(--scale-factor));
}

.pulse, .weight, .bloodpressure {
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  flex: 1;
}

.pulse p, .weight p, .bloodpressure p,
.pulse h3, .weight h3, .bloodpressure h3 {
  margin: 4px
}

.tab-content {
  max-height: 100%; /* 부모의 최대 높이를 사용 */
  overflow-y: auto; /* 스크롤 활성화 */
}

.chartcontainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}